<template>
<div class="content">
    <div class="row">

        <div class="col-md-12">
            <vuestic-widget headerText="Nuevo producto">

                <div class="row">

                    <div class="col-md-12">
                        <multiselect
                            v-model="idproduct"
                            label="name"
                            placeholder="Selected Element"
                            :options="products"
                            :searchable="true"
                            :allow-empty="false"
                            >
                            <template slot="singleLabel" slot-scope="{ option }">
                              Producto:
                              <label style="font-weight:600">{{ option.name }}</label>
                            </template>
                        </multiselect>

                    </div>

                </div>
                <br>
                 <div class="row">
                    <div class="col-md-6">
                        <div class="form-group with-icon-right" :class="{'has-error': errors.has('price'), 'valid': isSuccessfulNumberValid}">
                            <div class="input-group">
                                <input id="price" name="price" v-model="price" v-validate="'required'" />
                                <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                                <i class="fa fa-check valid-icon icon-right input-icon"></i>
                                <label class="control-label" for="price">{{'view.productbystation.fields.price' | translate}} </label><i class="bar"></i>
                                <small v-show="errors.has('price')" class="help text-danger">
                                  {{'view.productbystation.errors.price' | translate}}
                                                    </small>
                            </div>
                        </div>

                    </div>
                    <div class="col-md-6">
                        <div class="form-group with-icon-right" :class="{'has-error': errors.has('vat'), 'valid': isSuccessfulNumberValid}">
                            <div class="input-group">
                                <input id="vat" name="vat" v-model="vat" v-validate="'required'" />
                                <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                                <i class="fa fa-check valid-icon icon-right input-icon"></i>
                                <label class="control-label" for="price">{{'view.productbystation.fields.vat' | translate}} </label><i class="bar"></i>
                                 <small class="help text-secondary">
                                  El valor indicado sera un Porcentaje
                                  </small><br>
                                <small v-show="errors.has('vat')" class="help text-danger">
                                  {{'view.productbystation.errors.vat' | translate}}
                                                    </small>
                            </div>
                        </div>


                    </div>
                     <div class="col-md-6">
                        <div class="form-group with-icon-right" :class="{'has-error': errors.has('vat'), 'valid': isSuccessfulNumberValid}">
                            <div class="input-group">
                                <input id="order" name="order" v-model="order" v-validate="'required'" />
                                <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                                <i class="fa fa-check valid-icon icon-right input-icon"></i>
                                <label class="control-label" for="price">{{'view.productbystation.fields.order' | translate}} </label><i class="bar"></i>
                                 <small class="help text-secondary">
                                  Permite ordenar en los listados de la aplicacion
                                  </small><br>
                                <small v-show="errors.has('order')" class="help text-danger">
                                  {{'view.productbystation.errors.order' | translate}}
                                                    </small>
                            </div>
                        </div>


                    </div>
                      <div class="col-md-6">
                        <label class="control-label" for="location">{{'view.station.fields.status' | translate}} </label>

                        <div class="form-group with-icon-right" :class="{'has-error': errors.has('active')}">

                            <div class="input-group">
                                <vuestic-switch v-model="active">
                                    <span slot="trueTitle">{{'view.event.fields.enabled' | translate}}</span>
                                    <span slot="falseTitle">{{'view.event.fields.disabled' | translate}}</span>
                                </vuestic-switch>
                                <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                                <i class="fa fa-check valid-icon icon-right input-icon"></i>

                                <small v-show="errors.has('active')" class="help text-danger">
                                                        {{ errors.first('active') }}
                                                    </small>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-8">
                    </div>

                </div>
                <div class="row">
                    <button type="button" v-on:click="saveProductOnStation" class="btn btn-primary btn-micro">{{$t('view.productbystation.save')}}</button>
                </div>

            </vuestic-widget>

        </div>
    </div>
</div>
</template>

<script>
import Vue from 'vue'
import Multiselect from 'vue-multiselect'

import {
  SpringSpinner
} from 'epic-spinners'
import {
  mapState,
  mapActions
} from 'vuex'
export default {
  name: 'new-product-by-station',
  components: {
    SpringSpinner,
    Multiselect
  },
  mounted () {
    // checkbox and radios
   // console.log(this.idcompany);
    this.loadProductsByCompany(this.idcompany)
      .then(data => {
        this.products = data;
      })
      .catch(data => {
        this.addToastMessage({
          text: 'Error consultando usuarios',
          type: 'warning'
        })
      })
  },
  computed: {
    ...mapState({
      //products: state => state.product.products,
      idevent: state => state.event.event.id,
      idcompany: state => state.auth.company.id,
    }),
    isSuccessfulNameValid () {
      let isValid = false
      if (this.formFields.name) {
        isValid = this.formFields.name.validated && this.formFields.name.valid
      }
      return isValid
    },
    isSuccessfulNumberValid () {
      let isValid = false
      if (this.formFields.price) {
        isValid = this.formFields.price.validated && this.formFields.price.valid
      }
      return isValid
    },
    isSuccessfulLocationValid () {
      let isValid = false
      if (this.formFields.location) {
        isValid = this.formFields.location.validated && this.formFields.location.valid
      }
      return isValid
    },
  },
  data () {
    return {
      header: 'Nueva Estación',
      productoptions: [],
      price: '',
      vat: '',
      order:'',
      location: '',
      idproduct: null,
      active: 1,
      products:[],

      eventoptions: null,

    }
  },
  methods: {
    ...mapActions([
      'loadProductsByCompany',
      'storeProductStation',
      'addToastMessage'
    ]),
    backPage: function () {
      this.$router.go(-1)
    },
    saveProductOnStation () {
      this.$validator.validate().then(result => {
        if (result) {
          var idstation = this.$route.params.idstation
          this.storeProductStation({
            id_product: this.idproduct.id,
            id_station: idstation,
            price: this.price,
            vat: this.vat,
            order:this.order,
            active: this.active,
          })
            .then((data) => {
              this.addToastMessage({
                text: data.message,
                type: 'success'
              })
            })
            .catch((data) => {
              this.error = data.message
              this.addToastMessage({
                text: data.message,
                type: 'warning'
              })
            })
        }
      })
    }
  }
}
</script>

<style lang="scss">
.color-icon-label-table {
    td:first-child {
        width: 1rem;
    }
}
.multiselect--active {
  z-index: 9999;
}
</style>
